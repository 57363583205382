import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {P, lightBeige} from '../../utils/content';
import 'react-perfect-scrollbar/dist/css/styles.css';

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const SP = styled(P)`
	text-align: ${props => (props.alignRight ? 'right' : 'justify')};
	@media screen and (max-width: 900px) {
		text-align: ${props => (props.alignRight ? 'right' : 'left')};
	}
`;
const Title = styled('span')`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
`;

const HomeEquipeContentMain = styled('div')`
	max-height: 70vh;
	animation: ${slideTop} 0.8s ease-out;
	padding-left: 20px;
	padding-right: 20px;
	@media screen and (max-width: 900px) {
		width: calc(100vw - 80px);
		height: calc(100vh - 220px);
		max-height: calc(100vh - 220px);
		padding-left: 0px;
		padding-right: 0px;
	}
`;

class HomeEquipeContent extends Component {
	render() {
		return (
			<HomeEquipeContentMain>
				<PerfectScrollbar>
					<SP>
						<Title>nina Ayachi</Title> <br />
						<br />
						Nina Ayachi est diplômée d’un Master Arts de la scène à
						l’ENS de Lyon, où elle rencontre notamment Éric Charon,
						Maguy Marin, Frank Vercruyssen, Grégoire Ingold, Damien
						Manivel, et collabore avec la Comédie de Saint-Étienne.
						Elle est assistante à la mise en scène puis actrice dans{' '}
						<i>Bittersweet</i>, <i>Éclairage Public</i> et{' '}
						<i>Au revoir mon amour</i> de Victor Inisan. En 2018,
						elle accompagne la tournée d’Inside et devient
						assistante à la mise en scène pour{' '}
						<i>Comment s’en sortir sans sortir</i> (mises en scène
						Frédérique Aït-Touati). Elle participe au festival{' '}
						<i>Mondes possibles</i> en mai 2018 aux Amandiers de
						Nanterre. Nina Ayachi est actuellement stagiaire lumière au CDN de La Criée à Marseille.
						<br />
					</SP>
					<SP>
						<Title>ruben Badinter</Title> <br />
						<br />
						Ruben Badinter suit les enseignements de Bertrand
						Chauvet (CPGE du Lycée Lakanal) et de Gaëtan Peau
						(Compagnie Cousin d’Edgar) avant d’intégrer le
						Laboratoire de Formation au Théâtre Physique (LFTP) de
						Montreuil ou il se forme en jeu masqué et en danse avec
						notamment Peggy Dias, Tatanka Gombaud et Sebastien
						Perrault, ainsi qu’en théâtre avec Lorraine de Sagazan,
						Thomas Bouvet, Florian Pautasso et Benjamin Porée. Il
						réalise également plusieurs stages à PARTS et à Ultima
						Vez à Bruxelles, et étudie la danse contemporaine au
						conservatoire du 12e arrondissement.
						<br />
					</SP>
					<SP>
						<Title>jean-baptiste Cautain</Title> <br />
						<br />
						Jean-Baptiste Cautain se forme en CPGE littéraire à
						Nanterre où il suit l’enseignement pratique et théorique
						de Julien Dieudonné et de Damien Manivel. Il est diplômé
						du Conservatoire d’Aubervilliers, dans la classe de
						Laurence Causse, Patricia Dolambi et Frédéric Robouant.
						Il joue pour Sarah Mouline dans{' '}
						<i>Du sable et des playmobil®</i> et pour Clara Chrétien
						dans <i>L'Empreinte</i>. Il met actuellement en scène
						une création originale, <i>La Mesure</i>.
						<br />
					</SP>
					<SP>
						<Title>clara Chrétien</Title> <br />
						<br />
						Diplômée d’un Master de Lettres Modernes à la
						Sorbonne-Nouvelle, Clara Chrétien s’est formée en jeu et
						dramaturgie à la DAMU (École Nationale de théâtre de
						République-Tchèque), au conservatoire du 19e
						arrondissement dans la classe d’Emilie-Anna Maillet
						ainsi que dans le Master professionnel « Dramaturgie et
						écriture scénique » de l’Université d’Aix-Marseille.
						Elle travaille aujourd’hui avec les compagnies Le
						Sycomore et La Ligne pour lesquelles, dans lesquelles
						elle est actrice, dramaturge et metteuse en scène.
						<br />
					</SP>
					<SP>
						<Title>gaspar Delusio</Title> <br />
						<br />
						Formé en khâgne littéraire dans le classe de Bertrand
						Chauvet, Gaspar Delusio dirige la création de{' '}
						<i>Grand-Peur et Misère du IIIe Reich</i> de Berltolt
						Brecht, avant d’intégrer le conservatoire du 13ème
						arrondissement, où il suit l'enseignement de François
						Clavier. Dans le même temps, il fonde la compagnie
						Bacchantes The Road et joue dans{' '}
						<i>La Famille Schroffenstein</i>
						de Kleist (théâtre de l’ENS-Ulm), puis dans{' '}
						<i>Hommage à un ami disparu et autres histoires</i>{' '}
						(prix du jury Festival A Contre Sens, prix de la ville
						Festival de Cabourg, prix du jury et du public au
						Festival de Lille 3). Il collabore avec Victor Inisan
						pour la création d’<i>Au revoir mon amour</i>.
						<br />
					</SP>
					<SP>
						<Title>juliette de Ribaucourt</Title> <br />
						<br />
						Formée au Cours Florent ainsi qu’aux Conservatoires du
						13e et du 8e arrondissement de Paris, Juliette de
						Ribaucourt travaille notamment avec Marie-Alix Coste de
						Bagneaux, Agnès Adam et François Clavier. Elle participe
						notamment aux spectacles du Groupe Le Sycomore et du
						Carrelage Collectif, pour lequel elle joue dans{' '}
						<i>La Pyramide !</i> de Julien Sicot et Jules de Mickaël
						Allouche.
						<br />
					</SP>
					<SP>
						<Title>giulia de Sia</Title> <br />
						<br />
						Giulia de Sia se forme au théâtre en classe préparatoire
						au Lycée Lakanal (Sceaux) ainsi qu’au Conservatoire du
						sixième arrondissement. Elle s’initie également au chant
						lyrique dans la classe d’Anne-Valérie Niès. Elle joue
						notamment dans <i>Bittersweet</i> du Collectif JOKLE à
						Théâtre Ouvert, <i>Ground Zero</i> de la compagnie Avant
						l’Aube au festival Off d’Avignon et <i>Chasse-Taupes</i>{' '}
						du Collectif OSOR. Elle est également actrice dans{' '}
						<i>Billie</i> mis en scène par Lucie Leclerc ainsi que
						dans la création collective autour de la{' '}
						<i>Chevauchée sur le lac de Constance</i> dirigée par
						Roberto Jean.
						<br />
					</SP>
					<SP>
						<Title>salomé Dugraindelorge</Title> <br />
						<br />
						Salomé Dugraindelorge s’est formée au CRR de Créteil en
						jeu, écriture et danse — où elle prend notamment part à
						la recréation du spectacle <i>Kontaktoff</i> sous la
						direction de Maria Angela Siani. Elle est chorégraphe
						dans <i>Éclairage Public</i>, mis en scène par Victor
						Inisan, danseuse dans <i>4.48 Psychose</i> mis en scène
						par Juana Tupper, et performeuse dans le théâtre magique
						du <i>Loup des Steppes</i> créé par Mélina Despretz.
						Elle est également diplômée en anthropologie et
						ethnoscénologie à l’IHEAL (Institut des Hautes Études
						d’Amérique Latine) de Paris.
						<br />
					</SP>
					<SP>
						<Title>juliette Évenard</Title> <br />
						<br />
						Après des études en CPGE littéraire puis au
						Conservatoire de Caen, Juliette Évenard intègre l’ERACM
						en 2016. Au cours de ces années, elle a notamment pu
						travailler avec Gurshard Shaheman (
						<i>
							Il pourra toujours dire que c’est pour l’amour du
							prophète
						</i>
						, <i>Potentia Gaudendi</i>) ou Emma Dante (
						<i>Extra Moenia</i>). Pour le Groupe Le Sycomore, elle
						joue dans <i>L’Empreinte</i>, mis en scène par Clara
						Chrétien.
						<br />
					</SP>
					<SP>
						<Title>zoé Guillemaud</Title> <br />
						<br />
						Zoé Guillemaud est diplômée du Conservatoire du 14e
						arrondissement de Paris, sous le regard de Nathalie
						Bécue. Elle y rencontre Nadia Vadori-Gauthier, Marion
						Delplancke, Cécile Lana Martin et suit une formation de
						danse-théâtre au près de Diana Ringel. Parallèlement,
						elle réalise une Licence d’Études Théâtrales à la
						Sorbonne-Nouvelle. Elle joue notamment dans{' '}
						<i>Pointe l’aube</i> de Pierre Pfauwadel,{' '}
						<i>La Promesse</i> d'Isabelle Janier,{' '}
						<i>La Pyramide !</i>
						de Julien Sicot et <i>
							Cher Diego, Quiela t'embrasse
						</i>{' '}
						de Cristina Strelkova.
						<br />
					</SP>
					<SP>
						<Title>nicolas Hadot</Title> <br />
						<br />
						Nicolas Hadot est concepteur son et sonorisateur pour le
						théâtre. Il assure sa formation à l’ENSATT en
						réalisation sonore, après une courte expérience en tant
						qu’acteur. Il travaille pour divers metteurs en scènes,
						tels que Catherine Hargreaves, Mohamed El Khatib ou Anne
						Monfort. Il fait partie de l’équipe technique du Théâtre
						de la Colline ainsi que de la Grande Halle de la
						Villette.
						<br />
					</SP>
					<SP>
						<Title>victor Inisan</Title> <br />
						<br />
						Diplômé d’un Master Arts de la scène de l’ENS de Lyon,
						Victor Inisan poursuit un doctorat à l’Université de
						Lille 3. Il est metteur en scène au sein du Collectif
						JOKLE puis du Sycomore (<i>Bittersweet</i>,{' '}
						<i>Au revoir mon amour</i>, <i>Éclairage Public</i>),
						dramaturge accompagné par l’ENSATT, assistant pour les
						metteurs en scène Anthony Le Foll (<i>Fourberies</i>) et
						Frédérique Aït-Touati (
						<i>Comment s’en sortir sans sortir</i>), ainsi que
						rédacteur théâtre et danse au sein de la revue critique{' '}
						<i>I/O Gazette</i>.
						<br />
					</SP>
					<SP>
						<Title>adrien Madinier</Title> <br />
						<br />
						Diplômé d’un Master de Philosophie à la Sorbonne, Adrien
						Madinier se forme au théâtre dans la CPGE Joliot Curie
						de Nanterre puis aux Conservatoires du 13e et du 8e
						arrondissement avec François Clavier et Agnès Adam. Il
						joue notamment dans <i>Bittersweet</i> du Collectif
						JOKLE, <i>Les Anormaux</i> du Collectif Aubjectif,{' '}
						<i>Éclairage Public</i> et <i>Au revoir mon amour</i>
						du Groupe Le Sycomore, <i>Jules</i> du Carrelage
						Collectif, et dans la création collective autour de la
						<i>Chevauchée sur le lac de Constance</i> dirigée par
						Roberto Jean.
						<br />
					</SP>
					<SP>
						<Title>stéphane Monpetit</Title> <br />
						<br />
						Après une CPGE littéraire au lycée Joliot-Curie
						(Nanterre), Stéphane Monpetit intègre l’École du Jeu
						sous la direction de Delphine Eliet. Il joue au théâtre
						dans <i>Une Poétique nommée relation</i>, mis en scène
						par Roberto Jean (présenté au festival de Villerville
						2017 et au 104 en 2018), et dans{' '}
						<i>Demain il neigeait</i>, mis en scène par Pauline
						Mongin (Centre d'Animation des Halles). Poursuivant sa
						formation auprès d’Alexandre Louschik et d’Alexandre Del
						Perugia - ancien directeur du CNAC -, il joue dans les
						courts-métrages de Doriane Berantelli (<i>Deux Amis</i>,{' '}
						<i>À point</i> et <i>Cerveaux</i>) et de Paul Ménagé (
						<i>Sans dire au revoir</i>). Pour la télévision, il
						incarne le personnage d'Elliot dans{' '}
						<i>Un Si Grand Soleil</i> (France 2).
						<br />
					</SP>
					<SP>
						<Title>paul Scarfoglio</Title> <br />
						<br />
						Paul Scarfoglio se forme dans la compagnie du Théâtre de
						l’imprévu de Vincennes, puis aux conservatoires du 11e
						et du 13e arrondissement, ainsi qu’a l’Ecole Paris
						Marais. Depuis quelques années, il joue a la télévision,
						notamment dans la série <i>Les Emmerdeurs</i>,{' '}
						<i>Les Grands</i> et <i>Skam</i>, ainsi qu’au cinéma,
						dans <i>Seuls</i> de David Moreau et{' '}
						<i>Un sac de billes</i> de Christian Duguay. En 2018, il
						co-fonde le Carrelage Collectif, dans lequel il joue{' '}
						<i>Jules</i>, présenté au Théâtre de Belleville, tout en
						continuant sa formation au conservatoire du 8e
						arrondissement.
						<br />
					</SP>
				</PerfectScrollbar>
			</HomeEquipeContentMain>
		);
	}
}

export default HomeEquipeContent;

import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import {withRouter} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
	ImgLogo,
} from '../../../utils/content';

import public1 from './ep1.jpg';
import public2 from './ep2.jpg';
import public3 from './ep3.jpg';
import public4 from './ep4.jpg';
import public6 from './ep6.jpg';
import public7 from './ep7.jpg';
import public8 from './ep8.jpg';


import logo1 from './logos/carrelage.jpg';
import logo2 from './logos/cite.png';
import logo3 from './logos/ecarts.jpg';
import logo4 from './logos/ens-psl.png';
import logo5 from './logos/espci.png';
import logo6 from './logos/espgg.jpg';
import logo7 from './logos/opium.png';
import logo8 from './logos/oppidum.jpg';
import logo9 from './logos/silencio.png';
import logo10 from './logos/treveoption2.jpg';


import {ReactComponent as logo} from '../../logo.svg';

import {
	FlexRow,
	H4,
	darkBeige,
	primaryWhite,
	lightBeige,
	P,
} from '../../../utils/content';

const AppIcon = styled('div')`
	transition: all 0.3s ease;
	&:hover {
		filter: blur(3px);
	}
	cursor: pointer;
	@media screen and (min-width: 900px) {
		display: none;
	}
`;

const Logo = styled(logo)`
	width: 100px;
	height: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
	path {
		fill: white;
	}
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const Title = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 8px;
	color: ${props => (props.isActive && !props.alwaysWhite ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	font-size: 110%;
	text-align: center;
	&:hover {
		color: ${props => (props.alwaysWhite ? primaryWhite : darkBeige)};
	}
`;

const PlayRow = styled(FlexRow)`
	@media screen and (max-width: 900px) {
		flex-direction: column-reverse;
	}
`;

const PlaySidebar = styled('div')`
	max-height: calc(100vh - 80px);
	@media screen and (max-width: 900px) {
		margin-top: 40px;
	}
`;

const SP = styled(P)`
	text-align: justify;
	@media screen and (max-width: 900px) {
		text-align: left;
	}
`;

const SidebarTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${props => (props.isActive ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	&:hover {
		color: ${darkBeige};
	}
`;

const SidebarPlayTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	margin-top: 20px;
	line-height: 1.5;
	@media screen and (min-width: 900px) {
		display: ${props => (props.isMobile ? 'none' : 'block')};
	}
	@media screen and (max-width: 900px) {
		display: ${props => (props.isMobile ? 'block' : 'none')};
	}
`;

const SidebarPlayInfos = styled(P)`
	margin-top: 20px;
`;

const SubTitle = styled('span')`
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-variant: small-caps;
	font-size: 110%;
`;

const PlayContentWrapper = styled('div')`
	padding-left: 40px;
	padding-right: 40px;
	flex: 2;
	@media screen and (max-width: 900px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const PlayContent = styled('div')`
	margin-left: auto;
	margin-right: auto;
	margin-top: 15vh;
	max-width: 800px;
	animation: ${slideTop} 0.8s ease-out;
	@media screen and (max-width: 900px) {
		margin-top: 20px;
		min-height: calc(100vh - 230px);
	}
`;

const EpMain = styled('div')`
	width: calc(100vw - 80px);
`;

const A = styled('a')`
	color: ${lightBeige};
`;

class Ep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boxWidth: 0,
		};
		this.playMain = React.createRef();
	}

	updateDimensions = () => {
		if (this.playMain != null) {
			this.setState({
				boxWidth: this.playMain.getBoundingClientRect().width,
			});
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	compomentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	render() {
		return (
			<EpMain>
				<AppIcon
					onClick={() => {
						this.props.history.push('/home');
					}}
				>
					<Logo />
					<Title alwaysWhite={true}>le sycomore</Title>
				</AppIcon>
				<PlayRow>
					<PlaySidebar>
						<SidebarTitle
							onClick={() => {
								this.props.history.push('/home');
							}}
						>
							retour
						</SidebarTitle>
						<SidebarPlayTitle isMobile={false}>
							éclairage <br /> public
						</SidebarPlayTitle>
						<SidebarPlayInfos>
							<SubTitle>conception:</SubTitle>
							<br /> Nina Ayachi
							<br /> Salomé Dugraindelorge
							<br /> Victor Inisan
							<br /> Adrien Madinier
							<br />
							<br />
							<SubTitle>mise en scène:</SubTitle>
							<br />
							Victor Inisan
							<br />
							<br />
							<SubTitle>Direction d’acteurs: </SubTitle>
							<br />
							Adrien Madinier
							<br />
							<br />
							<SubTitle>chorégraphie: </SubTitle>
							<br />
							Salomé Dugraindelorge
							<br />
							<br />
							<SubTitle>avec:</SubTitle>
							<br /> Nina Ayachi
							<br /> Victor Inisan
							<br /> Adrien Madinier
							<br />
							<br /> <ImgLogo src={logo1} alt="" ></ImgLogo> <ImgLogo src={logo2} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo3} alt="" ></ImgLogo> <ImgLogo src={logo4} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo5} alt="" ></ImgLogo> <ImgLogo src={logo6} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo7} alt="" ></ImgLogo> <ImgLogo src={logo8} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo9} alt="" ></ImgLogo> <ImgLogo src={logo10} alt="" ></ImgLogo>
						</SidebarPlayInfos>
					</PlaySidebar>
					<PlayContentWrapper>
						<PerfectScrollbar>
							<PlayContent
								innerRef={(elem) => {
									this.playMain = elem;
								}}
							>
								<SP>
									<SidebarPlayTitle isMobile={true}>
										éclairage <br /> public
									</SidebarPlayTitle>
								</SP>
								<SP>
									<SubTitle>résumé</SubTitle>
									<br />
									Tous les soirs, des milliers d’insectes
									attirés par la lumière artificielle tournent
									et s’épuisent autour des réverbères.
								</SP>
								<SP>
									Concernés par le phénomène de pollution
									lumineuse, un duo de scientifiques cherche à
									revivre en direct le trajet fatal d’un
									insecte désorienté par la lumière. Mais afin
									d’obtenir des résultats plus sensibles,
									ceux-ci décident de redistribuer
									considérablement les rôles&nbsp;: une
									expérience interactive débute alors pour le
									spectateur.
								</SP>
								<SP>
									À la lisière de la performance,{' '}
									<i>Éclairage Public</i> imagine les chemins
									émotifs d’une espèce menacée par la
									disparition de la nuit.
								</SP>
								<SP>
									<A href='/dossier_eclairage_public.pdf'>Télécharger le dossier d'<i>Eclairage public</i></A>
								</SP>
								<SP>
									<SubTitle>dates:</SubTitle>
									<br /> 20 septembre 2018 - <i>Landy Sauvage</i> - Sortie de Résidence (Saint-Denis)
									<br />
									<br /> 21-28 septembre 2018 - <i>École Normale Supérieure Ulm</i> - Festival TREVE (Paris 5e)
									<br />
									<br /> 04 octobre 2018 - <i>Silencio Club</i> - Soirée «Tout le monde a peur du noir» (Paris 2e)
									<br />
									<br /> 05 janvier 2019 - <i>Espace Oppidum</i> - Festival Chantiers Publics (Paris 11e)
									<br />
									<br /> 25-26 janvier 2019 - <i>Château de Beaulieu</i> - Festival Nuits du Sycomore (Mareuil-Sur-Belle)
									<br />
									<br /> 20 avril 2019 - <i>Théâtre de la Cité Internationale</i> - Festival Écarts (Paris 14e)
									<br />
									<br /> 09 novembre 2019 - <i>Université de Lille</i> - Colloque « Lumière Matière » (Villeneuve d’Ascq)
								</SP>
								<Carousel autoPlay={true} infiniteLoop={true} width={`${this.state.boxWidth}px`}>
									<div><img src={public1} alt="" /></div>
									<div><img src={public2} alt="" /></div>
									<div><img src={public3} alt="" /></div>
									<div><img src={public4} alt="" /></div>
									<div><img src={public6} alt="" /></div>
									<div><img src={public7} alt="" /></div>
									<div><img src={public8} alt="" /></div>
								</Carousel>
							</PlayContent>
						</PerfectScrollbar>
					</PlayContentWrapper>
				</PlayRow>
			</EpMain>
		);
	}
}

export default withRouter(Ep);

import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import {withRouter} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
	ImgLogo,
} from '../../../utils/content';

import empreinte01 from './empreinte01.jpg';

import logo1 from './logos/adis.jpg';
import logo2 from './logos/crous_marseille.jpg';
import logo3 from './logos/extreme_jonglerie.png';
import logo4 from './logos/faiar.jpg';
import logo5 from './logos/la_deviation.png';
import logo6 from './logos/mjc_prevert.jpeg';
import logo7 from './logos/sarev.png';
import logo8 from './logos/universite_marseille.svg';
import logo9 from './logos/vitez.jpg';


import {ReactComponent as logo} from '../../logo.svg';

import {
	FlexRow,
	H4,
	darkBeige,
	primaryWhite,
	lightBeige,
	P,
} from '../../../utils/content';

const AppIcon = styled('div')`
	transition: all 0.3s ease;
	&:hover {
		filter: blur(3px);
	}
	cursor: pointer;
	@media screen and (min-width: 900px) {
		display: none;
	}
`;

const Logo = styled(logo)`
	width: 100px;
	height: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
	path {
		fill: white;
	}
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const Title = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 8px;
	color: ${props => (props.isActive && !props.alwaysWhite ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	font-size: 110%;
	text-align: center;
	&:hover {
		color: ${props => (props.alwaysWhite ? primaryWhite : darkBeige)};
	}
`;

const PlayRow = styled(FlexRow)`
	@media screen and (max-width: 900px) {
		flex-direction: column-reverse;
	}
`;

const PlaySidebar = styled('div')`
	max-height: calc(100vh - 80px);
	@media screen and (max-width: 900px) {
		margin-top: 40px;
	}
`;

const SP = styled(P)`
	text-align: justify;
	@media screen and (max-width: 900px) {
		text-align: left;
	}
`;

const SidebarTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${props => (props.isActive ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	&:hover {
		color: ${darkBeige};
	}
`;

const SidebarPlayTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	margin-top: 20px;
	line-height: 1.5;
	@media screen and (min-width: 900px) {
		display: ${props => (props.isMobile ? 'none' : 'block')};
	}
	@media screen and (max-width: 900px) {
		display: ${props => (props.isMobile ? 'block' : 'none')};
	}
`;

const SidebarPlayInfos = styled(P)`
	margin-top: 20px;
`;

const SubTitle = styled('span')`
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-variant: small-caps;
	font-size: 110%;
`;

const PlayContentWrapper = styled('div')`
	padding-left: 40px;
	padding-right: 40px;
	flex: 2;
	@media screen and (max-width: 900px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const PlayContent = styled('div')`
	margin-left: auto;
	margin-right: auto;
	margin-top: 15vh;
	max-width: 800px;
	animation: ${slideTop} 0.8s ease-out;
	@media screen and (max-width: 900px) {
		margin-top: 20px;
		min-height: calc(100vh - 230px);
	}
`;

const EmpreinteMain = styled('div')`
	width: calc(100vw - 80px);
`;

class Empreinte extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boxWidth: 0,
		};
		this.playMain = React.createRef();
	}

	updateDimensions = () => {
		if (this.playMain != null) {
			this.setState({boxWidth: this.playMain.getBoundingClientRect().width});
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	compomentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	render() {
		return (
			<EmpreinteMain>
				<AppIcon
					onClick={() => {
						this.props.history.push('/home');
					}}
				>
					<Logo />
					<Title alwaysWhite={true}>le sycomore</Title>
				</AppIcon>
				<PlayRow>
					<PlaySidebar>
						<SidebarTitle
							onClick={() => {
								this.props.history.push('/home');
							}}
						>
							retour
						</SidebarTitle>
						<SidebarPlayTitle isMobile={false}>
							l'empreinte
						</SidebarPlayTitle>
						<SidebarPlayInfos>
							<SubTitle>écriture:</SubTitle>
							<br /> Émilie Cavalieri
							<br /> Jean-Baptiste Cautain
							<br /> Juliette Évenard
							<br /> Stéphane Monpetit
							<br /> Juliette de Ribaucourt
							<br /> Clara Chrétien
							<br />
							<br />
							<SubTitle>mise en scène:</SubTitle>
							<br />
							Clara Chrétien
							<br />
							<br />
							<SubTitle>scénographie: </SubTitle>
							<br /> Charlotte Le Floch
							<br />
							<br />
							<SubTitle>construction: </SubTitle>
							<br /> Jerôme Gourves
							<br />
							<br />
							<SubTitle>création sonore: </SubTitle>
							<br /> Sinclair Ringenbach
							<br />
							<br />
							<SubTitle>création lumière: </SubTitle>
							<br /> Arthur Lavieille
							<br />
							<br />
							<SubTitle>avec :</SubTitle>
							<br /> Juliette de Ribaucourt
							<br /> Jean-Baptiste Cautain
							<br /> Juliette Évenard
							<br /> Stéphane Monpetit
							<br />
							<br /> <ImgLogo src={logo1} alt="" ></ImgLogo> <ImgLogo src={logo2} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo3} alt="" ></ImgLogo> <ImgLogo src={logo4} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo5} alt="" ></ImgLogo> <ImgLogo src={logo6} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo7} alt="" ></ImgLogo> <ImgLogo src={logo8} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo9} alt="" ></ImgLogo>
						</SidebarPlayInfos>
					</PlaySidebar>
					<PlayContentWrapper>
						<PerfectScrollbar>
							<PlayContent
								innerRef={(elem) => {
									this.playMain = elem;
								}}
							>
								<SidebarPlayTitle isMobile={true}>
									l'empreinte
								</SidebarPlayTitle>
								<SP>
									<SubTitle>résumé</SubTitle> <br />
									Devant la porte d’une chambre, trois individus attendent la délivrance
									d’une femme qui leur est chère. Au cœur de cette inquiétante et
									silencieuse veillée, tandis que l’on s’apprête péniblement à dîner,
									la Mort s’invite à la table.
									<br />
									<br />
									Révoltés, dégoûtés ou fascinés par cette présence qui les accable,
									ils tentent ensemble de lutter contre la fatalité. Sur le damier
									noir et blanc, qui dessine les contours du salon qui les abrite,
									débute alors un jeu entre les vivants et la mort.
									<br />
									<br />
									Composé de peu de mouvements et de beaucoup de silence, la danse
									macabre qu’est <i>L’Empreinte</i>, invite à rire au cœur même
									du douloureux combat qu’il faut livrer lorsque s’en vont les êtres aimés.
								</SP>
								<SP>
									<SubTitle>dates:</SubTitle>
									<br /> 11 octobre 2019 - <i>Théâtre Antoine-Vitez</i> - (Aix-En-Provence)
									<br />
								</SP>
								<Carousel autoPlay={true} infiniteLoop={true} width={`${this.state.boxWidth}px`}>
									<div><img src={empreinte01} alt="" /></div>
								</Carousel>
							</PlayContent>
						</PerfectScrollbar>
					</PlayContentWrapper>
				</PlayRow>
			</EmpreinteMain>
		);
	}
}

export default withRouter(Empreinte);

import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {P} from '../../utils/content';
import 'react-perfect-scrollbar/dist/css/styles.css';

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const SP = styled(P)`
	text-align: ${props => (props.alignRight ? 'right' : 'justify')};
	@media screen and (max-width: 900px) {
		text-align: ${props => (props.alignRight ? 'right' : 'left')};
	}
`;

const HomeAboutContentMain = styled('div')`
	max-height: 70vh;
	animation: ${slideTop} 0.8s ease-out;
	padding-left: 20px;
	padding-right: 20px;
	@media screen and (max-width: 900px) {
		height: calc(100vh - 220px);
		max-height: calc(100vh - 220px);
		padding-left: 0px;
		padding-right: 0px;
	}
`;

class HomeAboutContent extends Component {
	render() {
		return (
			<PerfectScrollbar>
				<HomeAboutContentMain>
					<SP alignRight>
						<i>
							«&nbsp;L’art de l’acteur ne consiste plus à informer
							les spectateurs sur l’acteur et sur la scène, mais
							au contraire à informer le spectateur sur
							lui-même.&nbsp;»
							<br />
							André Engel
						</i>
					</SP>
					<SP>
						Le Sycomore est un laboratoire artistique créé en 2018
						par une dizaine d’artistes mêlant formations théorique
						et pratique.
					</SP>
					<SP>
						Le groupe soutient actuellement cinq
						créations&nbsp;:&nbsp;
						<i>Au revoir mon amour</i>, <i>Éclairage Public</i>{' '}
						(mise en scène Victor Inisan),&nbsp;
						<i>L’Empreinte</i> (mise en scène Clara Chrétien),&nbsp;
						<i>Welcome to my life</i> (conception et mise en scène
						Victor Inisan et Adrien Madinier — création en
						cours)&nbsp; et <i>Le spectacle de la nature</i> (conception et mise
						en scène Nina Ayachi et Adrien Madinier — création en
						cours).
					</SP>
					<SP>
						Le Sycomore est accompagné par divers
						partenaires&nbsp;:, le Nouveau Théâtre de Montreuil,
						l’ENS de Paris, le groupe de recherche TREVE, La
						Distillerie (Aubagne), le Théâtre Les Gazelles
						(Aix-En-Provence), le Centre Culturel ADIS/Les Amandiers
						(Aix-En-Provence), la MJC Jacques Prévert
						(Aix-En-Provence), la revue Opium Philosophie, La
						Déviation (Marseille), l’ESPGG et l’ESPCI (Paris), le
						Théâtre Antoine Vitez (Aix-En-Provence), Extrême
						Jonglerie (Marseille), les dispositifs Acte&Fac, Culture
						ActionS, le Festival Écarts (Paris).
					</SP>
					<SP>
						Les membres du Sycomore, nourris d’un intérêt pour le
						théâtre « in situ », se retrouvent autour d’artistes qui
						les inspirent et les guident&nbsp;: André Engel, Witold
						Gombrowicz, Krystian Lupa, Tadeusz Kantor, Thom Luz,
						Kate McIntosh, François Tanguy.
					</SP>
				</HomeAboutContentMain>
			</PerfectScrollbar>
		);
	}
}

export default HomeAboutContent;

import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import {withRouter} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import {Carousel} from 'react-responsive-carousel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {ReactComponent as logo} from '../../logo.svg';

import {
	FlexRow,
	H4,
	darkBeige,
	primaryWhite,
	lightBeige,
	P,
} from '../../../utils/content';

const AppIcon = styled('div')`
	transition: all 0.3s ease;
	&:hover {
		filter: blur(3px);
	}
	cursor: pointer;
	@media screen and (min-width: 900px) {
		display: none;
	}
`;

const Logo = styled(logo)`
	width: 100px;
	height: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
	path {
		fill: white;
	}
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const Title = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 8px;
	color: ${props => (props.isActive && !props.alwaysWhite ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	font-size: 110%;
	text-align: center;
	&:hover {
		color: ${props => (props.alwaysWhite ? primaryWhite : darkBeige)};
	}
`;

const PlayRow = styled(FlexRow)`
	@media screen and (max-width: 900px) {
		flex-direction: column-reverse;
	}
`;

const PlaySidebar = styled('div')`
	max-height: calc(100vh - 80px);
	@media screen and (max-width: 900px) {
		margin-top: 40px;
	}
`;

const SP = styled(P)`
	text-align: justify;
	@media screen and (max-width: 900px) {
		text-align: left;
	}
`;

const SidebarTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${props => (props.isActive ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	&:hover {
		color: ${darkBeige};
	}
`;

const SidebarPlayTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	margin-top: 2v0px;
	line-height: 1.5;
	@media screen and (min-width: 900px) {
		display: ${props => (props.isMobile ? 'none' : 'block')};
	}
	@media screen and (max-width: 900px) {
		display: ${props => (props.isMobile ? 'block' : 'none')};
	}
`;

const SidebarPlayInfos = styled(P)`
	margin-top: 20px;
`;

const SubTitle = styled('span')`
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-variant: small-caps;
	font-size: 110%;
`;

const PlayContentWrapper = styled('div')`
	padding-left: 40px;
	padding-right: 40px;
	flex: 2;
	@media screen and (max-width: 900px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const PlayContent = styled('div')`
	margin-left: auto;
	margin-right: auto;
	margin-top: 15vh;
	max-width: 800px;
	animation: ${slideTop} 0.8s ease-out;
	@media screen and (max-width: 900px) {
		margin-top: 20px;
		min-height: calc(100vh - 230px);
	}
`;

const LaclMain = styled('div')`
	width: calc(100vw - 80px);
`;

const SidebarPlayTitleSpan = styled('span')`
	font-variant: small-caps;
	font-size: 15px;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	font-variant: none
`;


class Wtml extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boxWidth: 0,
		};
		this.playMain = React.createRef();
	}

	updateDimensions = () => {
		if (this.playMain != null) {
			this.setState({boxWidth: this.playMain.getBoundingClientRect().width});
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	compomentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	render() {
		return (
			<LaclMain>
				<AppIcon
					onClick={() => {
						this.props.history.push('/home');
					}}
				>
					<Logo />
					<Title alwaysWhite={true}>le sycomore</Title>
				</AppIcon>
				<PlayRow>
					<PlaySidebar>
						<SidebarTitle
							onClick={() => {
								this.props.history.push('/home');
							}}
						>
							retour
						</SidebarTitle>
						<SidebarPlayTitle isMobile={false}>
							welcome to my life <SidebarPlayTitleSpan><br /><i>(titre provisoire)</i></SidebarPlayTitleSpan>
						</SidebarPlayTitle>
						<SidebarPlayInfos>
							<SubTitle>
								conception
								<br /> et mise en scène:
							</SubTitle>
							<br /> Victor Inisan
							<br /> Adrien Madinier
							<br />
							<br />
							<SubTitle>chorégraphie:</SubTitle>
							<br /> Salomé Dugraindelorge
							<br />
							<br />
							<SubTitle>avec:</SubTitle>
							<br /> Juliette de Ribaucourt
							<br /> Victor Inisan
							<br /> Adrien Madinier
							<br />
							<br />
						</SidebarPlayInfos>
					</PlaySidebar>
					<PlayContentWrapper>
						<PerfectScrollbar>
							<PlayContent
								innerRef={(elem) => {
									this.playMain = elem;
								}}
							>
								<SP>
									<SubTitle>résumé</SubTitle>{' '}<br />
									Soucieux de comprendre l’influence de l’homme sur l’environnement, deux
									scientifiques se mettent en tête d’étudier les effets de la lumière
									artificielle sur les plantes. Il faut dire qu’en modifiant leur rythme jour/nuit,
									l’éclairage humain force les végétaux à libérer de l’énergie bien après le coucher
									du soleil... Privées de sommeil, voilà que les plantes
									apprennent peu à peu ce qu’est une « nuit blanche ».
								</SP>
								<SP>
									Dans une parabole écologique et poétique, le duo décide de mener
									l’expérience sur une jeune adolescente qui peine à trouver le repos.
									Au coeur d’un dispositif de lumières interactives, le spectateur
									pourra observer en direct les effets de l’insomnie chez un
									sujet encore fébrile. Car à l’image de la plante, l’adolescente
									s’achemine, elle aussi, vers sa pleine croissance... En dénichant le
									végétal à l’intérieur de l’humain (et vice-versa), <i>Welcome to my life</i> explore
									les tourments d’un monde de plus en plus exposé au surmenage
									et à la sur-sollicitation physique et psychologique.
								</SP>
							</PlayContent>
						</PerfectScrollbar>
					</PlayContentWrapper>
				</PlayRow>
			</LaclMain>
		);
	}
}

export default withRouter(Wtml);

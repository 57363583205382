import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import styled from 'react-emotion';
import Arma from './Arma';
import Ep from './Ep';
import Empreinte from './Empreinte';
import Wtml from './Wtml';
import Ritournelle from './Ritournelle';
import {Body} from '../../utils/content';

const PlayMain = styled('div')``;

const BodyMain = styled(Body)`
	padding: 20px 40px;

`;

class Play extends Component {
	render() {
		return (
			<PlayMain>
				<BodyMain>
					<Switch>
						<Route exact path="/play/arma" component={Arma} />
						<Route exact path="/play/ep" component={Ep} />
						<Route exact path="/play/empreinte" component={Empreinte} />
						<Route exact path="/play/wtml" component={Wtml} />
						<Route exact path="/play/ritournelle" component={Ritournelle} />
					</Switch>
				</BodyMain>
			</PlayMain>
		);
	}
}

export default Play;

import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import {withRouter} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import cerisaie1 from './cerisaie01.jpg';
import cerisaie2 from './cerisaie02.jpg';
import cerisaie3 from './cerisaie03.jpg';

import {ReactComponent as logo} from '../../logo.svg';

import {
	FlexRow,
	H4,
	darkBeige,
	primaryWhite,
	lightBeige,
	P,
} from '../../../utils/content';

const AppIcon = styled('div')`
	transition: all 0.3s ease;
	&:hover {
		filter: blur(3px);
	}
	cursor: pointer;
	@media screen and (min-width: 900px) {
		display: none;
	}
`;

const Logo = styled(logo)`
	width: 100px;
	height: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
	path {
		fill: white;
	}
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const Title = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 8px;
	color: ${props => (props.isActive && !props.alwaysWhite ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	font-size: 110%;
	text-align: center;
	&:hover {
		color: ${props => (props.alwaysWhite ? primaryWhite : darkBeige)};
	}
`;

const PlayRow = styled(FlexRow)`
	@media screen and (max-width: 900px) {
		flex-direction: column-reverse;
	}
`;

const PlaySidebar = styled('div')`
	max-height: calc(100vh - 80px);
	@media screen and (max-width: 900px) {
		margin-top: 40px;
	}
`;

const SP = styled(P)`
	text-align: justify;
	@media screen and (max-width: 900px) {
		text-align: left;
	}
`;

const SidebarTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${props => (props.isActive ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	&:hover {
		color: ${darkBeige};
	}
`;

const SidebarPlayTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	margin-top: 2v0px;
	line-height: 1.5;
	@media screen and (min-width: 900px) {
		display: ${props => (props.isMobile ? 'none' : 'block')};
	}
	@media screen and (max-width: 900px) {
		display: ${props => (props.isMobile ? 'block' : 'none')};
	}
`;

const SidebarPlayInfos = styled(P)`
	margin-top: 20px;
`;

const SubTitle = styled('span')`
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-variant: small-caps;
	font-size: 110%;
`;

const PlayContentWrapper = styled('div')`
	padding-left: 40px;
	padding-right: 40px;
	flex: 2;
	@media screen and (max-width: 900px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const PlayContent = styled('div')`
	margin-left: auto;
	margin-right: auto;
	margin-top: 15vh;
	max-width: 800px;
	animation: ${slideTop} 0.8s ease-out;
	@media screen and (max-width: 900px) {
		margin-top: 20px;
		min-height: calc(100vh - 230px);
	}
`;

const LaclMain = styled('div')`
	width: calc(100vw - 80px);
`;

const SidebarPlayTitleSpan = styled('span')`
	font-variant: small-caps;
	font-size: 15px;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	font-variant: none
`;

class Ritournelle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boxWidth: 0,
		};
		this.playMain = React.createRef();
	}

	updateDimensions = () => {
		if (this.playMain != null) {
			this.setState({boxWidth: this.playMain.getBoundingClientRect().width});
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	compomentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	render() {
		return (
			<LaclMain>
				<AppIcon
					onClick={() => {
						this.props.history.push('/home');
					}}
				>
					<Logo />
					<Title alwaysWhite={true}>le sycomore</Title>
				</AppIcon>
				<PlayRow>
					<PlaySidebar>
						<SidebarTitle
							onClick={() => {
								this.props.history.push('/home');
							}}
						>
							retour
						</SidebarTitle>
						<SidebarPlayTitle isMobile={false}>
							le spectacle de la nature<SidebarPlayTitleSpan><br /><i>(titre provisoire)</i></SidebarPlayTitleSpan>
						</SidebarPlayTitle>
						<SidebarPlayInfos>
							<SubTitle>
								conception
								<br /> et mise en scène:
							</SubTitle>
							<br /> Nina Ayachi
							<br /> Adrien Madinier
							<br />
							<br />
							<SubTitle>direction musicale:</SubTitle>
							<br /> Giulia de Sia
							<br />
							<br />
							<SubTitle>création lumière:</SubTitle>
							<br /> Nina Ayachi
							<br /> Victor Inisan
							<br /> Adrien Madinier
							<br />
							<br />
							<SubTitle>avec:</SubTitle>
							<br /> Ruben Badinter
							<br /> Jean-Baptiste Cautain
							<br /> Clara Chrétien
							<br /> Gaspar Delusio
							<br /> Juliette de Ribaucourt
							<br /> Giulia de Sia
							<br /> Salomé Dugraindelorge
							<br /> Zoé Guillemaud
							<br /> Victor Inisan
							<br /> Stéphane Monpetit
							<br /> Paul Scarfoglio
							<br />
							<br />
						</SidebarPlayInfos>
					</PlaySidebar>
					<PlayContentWrapper>
						<PerfectScrollbar>
							<PlayContent
								innerRef={(elem) => {
									this.playMain = elem;
								}}
							>
								<SP>
									<SidebarPlayTitle isMobile={true}>
										le spectacle de la nature
									</SidebarPlayTitle>
								</SP>
								<SP>
									<SubTitle>
										D’après <i>La Cerisaie</i> d’Anton
										Tchekhov
									</SubTitle>
								</SP>
								<SP>
									<SubTitle>résumé</SubTitle> <br />
									Sur scène, l'entrée des spectateurs réveille
									les souvenirs d'une vieille demeure
									endormie. Des êtres fondus depuis des années
									dans les murs décrépis et le mobilier
									vieilli, s'éveillent d'un long sommeil.
									Maladroits d'abord – tout juste décollés de
									l'oubli –, ils retrouvent peu à peu le
									chemin de leur existence passée et revivent
									les grandes scènes de leur vie défunte. Dans
									ces restes de maison en ruine, les fantômes
									de la Cerisaie fêtent leurs retrouvailles.
								</SP>
								<SP>
									Mais l'habitat a vieilli, il n'est plus tout
									à fait habitable – comme coincé entre le
									présent de la représentation et le passé du
									souvenir. Dans cette perspective, comment
									les personnages jouent avec les contraintes
									que leur pose cette maison précaire,
									inhabitable, sur le bord de s'écrouler ?
									Comment organiser un repas de famille quand
									il manque deux pieds à la table ? Comment
									s’émerveiller des cerisiers en fleurs s’il
									faut tenir la fenêtre pour les apercevoir ?
									Comment survivre si tout l'univers dans
									lequel évoluent ces êtres est comme mité, et
									définitivement désuet, survivant par on ne
									sait quel mystère devant les yeux du
									spectateur ? Dans ce microcosme au bord de
									l'effondrement, les personnages utilisent la
									disjonction de l'espace pour réagencer leur
									existence, là où elle risque à tout moment
									de sombrer dans l’oubli.
								</SP>
								<Carousel autoPlay={true} infiniteLoop={true} width={`${this.state.boxWidth}px`}>
									<div><img src={cerisaie1} alt="" /></div>
									<div><img src={cerisaie2} alt="" /></div>
									<div><img src={cerisaie3} alt="" /></div>
								</Carousel>
							</PlayContent>
						</PerfectScrollbar>
					</PlayContentWrapper>
				</PlayRow>
			</LaclMain>
		);
	}
}

export default withRouter(Ritournelle);

import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import {withRouter} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
	ImgLogo,
} from '../../../utils/content';


import arma1 from './1.jpg';
import arma2 from './2.jpg';
import arma3 from './3.jpg';
import arma4 from './4.jpg';
import arma5 from './5.jpg';
import arma6 from './6.jpg';
import arma7 from './7.jpg';

import logo1 from './logos/generateur.jpg';
import logo2 from './logos/crous-paris-300x300.png';
import logo3 from './logos/nouveau-theatre-montreuil.jpg';
import logo4 from './logos/sorbonne.jpg';

import {
	FlexRow,
	H4,
	darkBeige,
	primaryWhite,
	lightBeige,
	P,
} from '../../../utils/content';

import {ReactComponent as logo} from '../../logo.svg';

const AppIcon = styled('div')`
	transition: all 0.3s ease;
	&:hover {
		filter: blur(3px);
	}
	cursor: pointer;
	@media screen and (min-width: 900px) {
		display: none;
	}
`;

const Logo = styled(logo)`
	width: 100px;
	height: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
	path {
		fill: white;
	}
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const Title = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 8px;
	color: ${props => (props.isActive && !props.alwaysWhite ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	font-size: 110%;
	text-align: center;
	&:hover {
		color: ${props => (props.alwaysWhite ? primaryWhite : darkBeige)};
	}
`;

const PlayRow = styled(FlexRow)`
	@media screen and (max-width: 900px) {
		flex-direction: column-reverse;
	}
`;

const PlaySidebar = styled('div')`
	max-height: calc(100vh - 80px);
	@media screen and (max-width: 900px) {
		flex-direction: column-reverse;
	}
`;

const SP = styled(P)`
	text-align: justify;
	@media screen and (max-width: 900px) {
		text-align: left;
	}
`;

const SidebarTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${props => (props.isActive ? darkBeige : primaryWhite)};
	font-family: 'BWHaas', sans-serif;
	cursor: pointer;
	transition: color 0.3s ease;
	&:hover {
		color: ${darkBeige};
	}
`;

const SidebarPlayTitle = styled(H4)`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${primaryWhite};
	font-family: 'BWHaas', sans-serif;
	margin-top: 20px;
	line-height: 1.5;

	@media screen and (min-width: 900px) {
		display: ${props => (props.isMobile ? 'none' : 'block')};
	}
	@media screen and (max-width: 900px) {
		display: ${props => (props.isMobile ? 'block' : 'none')};
	}
`;

const SidebarPlayInfos = styled(P)`
	margin-top: 20px;
`;

const SubTitle = styled('span')`
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-variant: small-caps;
	font-size: 110%;
`;

const PlayContentWrapper = styled('div')`
	padding-left: 40px;
	padding-right: 40px;
	flex: 2;
	@media screen and (max-width: 900px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const PlayContent = styled('div')`
	margin-left: auto;
	margin-right: auto;
	margin-top: 15vh;
	max-width: 800px;
	animation: ${slideTop} 0.8s ease-out;
	@media screen and (max-width: 900px) {
		margin-top: 20px;
		min-height: calc(100vh - 230px);
	}
`;

const ArmaMain = styled('div')`
	width: calc(100vw - 80px);
`;

const A = styled('a')`
	color: ${lightBeige};
`;

class Arma extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boxWidth: 0,
		};
		this.playMain = React.createRef();
	}

	updateDimensions = () => {
		if (this.playMain != null) {
			this.setState({boxWidth: this.playMain.getBoundingClientRect().width});
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
		// document.getElementById('bodymain').setAttribute('background-image', null);
	}

	compomentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	render() {
		return (
			<ArmaMain id="kkkkk">
				<AppIcon
					onClick={() => {
						this.props.history.push('/home');
					}}
				>
					<Logo />
					<Title alwaysWhite={true}>le sycomore</Title>
				</AppIcon>
				<PlayRow>
					<PlaySidebar>
						<SidebarTitle
							onClick={() => {
								this.props.history.push('/home');
							}}
						>
							retour
						</SidebarTitle>
						<SidebarPlayTitle isMobile={false}>
							au revoir <br /> mon amour
						</SidebarPlayTitle>
						<SidebarPlayInfos>
							<SubTitle>
								conception visuelle
								<br /> et mise en scène:
							</SubTitle>
							<br />
							Victor Inisan
							<br />
							<br />
							<SubTitle>collaboration artistique : </SubTitle>
							<br /> Nina Ayachi
							<br /> Gaspar Delusio
							<br />
							<br />
							<SubTitle>création sonore: </SubTitle>
							<br />
							Pierre Fourmeau
							<br /> Nicolas Hadot
							<br />
							<br />
							<SubTitle>avec:</SubTitle>
							<br /> Nina Ayachi
							<br /> Ruben Badinter
							<br /> Jean-Baptiste Cautain
							<br /> Clara Chrétien
							<br /> Juliette De Ribaucourt
							<br /> Giulia de Sia
							<br /> Salomé Dugraindelorge
							<br /> Zoé Guillemaud
							<br /> Adrien Madinier
							<br />
							<br /> <ImgLogo src={logo1} alt="" ></ImgLogo> <ImgLogo src={logo2} alt="" ></ImgLogo>
							<br />
							<br /> <ImgLogo src={logo3} alt="" ></ImgLogo> <ImgLogo src={logo4} alt="" ></ImgLogo>
						</SidebarPlayInfos>
					</PlaySidebar>
					<PlayContentWrapper>
						<PerfectScrollbar>
							<PlayContent
								innerRef={(elem) => {
									this.playMain = elem;
								}}
							>
								<SidebarPlayTitle isMobile={true}>
									au revoir <br /> mon amour
								</SidebarPlayTitle>

								<SP>
									<SubTitle>résumé</SubTitle> <br />
									Louis a choisi de mourir. Pour son dernier
									soir, il a convié tous ses proches autour de
									lui, en compagnie de sa femme Judy : pour
									rire et pleurer, parler et chanter ensemble.
								</SP>
								<SP>
									Mais c’est une cérémonie un peu spéciale qui
									les attend : alors que Louis est aux portes
									de la mort, tout bascule dans un rituel
									hypnotique, durant lequel les sept parties
									de son esprit vont peu à peu se détacher de
									son corps. De l’émotion à la mémoire, en
									passant par l’amour, chacun pourra ainsi se
									rappeler de quoi Louis était forgé.
								</SP>
								<SP>
									À la lisière de la performance,{' '}
									<i>Au revoir mon amour</i> trace une
									parabole visuelle et poétique sur la
									disparition des êtres chers.{' '}
								</SP>
								<SP>
									<A href='/dossier_arma.pdf'>Télécharger le dossier d'<i>Au revoir mon amour</i></A>
								</SP>
								<SP>
									<SubTitle>dates:</SubTitle>
									<br /> 18 Novembre 2018 - <i>Le Générateur</i> - Soirée «À l’écoute du bal rêvé» (Gentilly)
									<br />
									<br /> 23-25 novembre 2018 - <i>Loft du performer Alberto Sorbelli</i> (Paris 11e)
									<br />
									<br /> 25-26 janvier 2019 - <i>Château de Beaulieu</i> - Festival Nuits du Sycomore (Mareuil-Sur-Belle)
									<br />
									<br /> 14 juin 2019 - <i>Nouveau Théâtre de Montreuil</i> - Sortie de Résidence
								</SP>
								<Carousel autoPlay={true} infiniteLoop={true}>
									<div><img src={arma1} alt="" /></div>
									<div><img src={arma2} alt="" /></div>
									<div><img src={arma3} alt="" /></div>
									<div><img src={arma4} alt="" /></div>
									<div><img src={arma5} alt="" /></div>
									<div><img src={arma6} alt="" /></div>
									<div><img src={arma7} alt="" /></div>
								</Carousel>
							</PlayContent>
						</PerfectScrollbar>
					</PlayContentWrapper>
				</PlayRow>
			</ArmaMain>
		);
	}
}

export default withRouter(Arma);

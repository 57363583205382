import React, {Component} from 'react';
import styled, {keyframes} from 'react-emotion';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {lightBeige} from '../../utils/content';

import visuel01 from './visuel01.jpg';
import visuel02 from './visuel02.jpg';
import visuel03 from './visuel03.jpg';
import visuel04 from './visuel04.jpg';
import visuel05 from './visuel05.jpg';
import visuel06 from './visuel06.jpg';
import visuel07 from './visuel07.jpg';
import visuel08 from './visuel08.jpg';
import visuel09 from './visuel09.jpg';
import visuel10 from './visuel10.jpg';

const slideTop = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, -300%, 0);
		filter: blur(3px);
	}
	to {
		filter: blur(0px);
		opacity: 1;
		transform: translate3d(0, 0%, 0);
	}
`;

const HomeEventsContentMain = styled('div')`
	animation: ${slideTop} 0.8s ease-out;
	@media screen and (max-width: 900px) {
		display: block;
		padding-left: 20px;
		padding-right: 20px;
		height: calc(100vh - 220px);
		margin-top: 20px;
	}
`;

const Title = styled('span')`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-size: 110%;
`;

const TitleStrike = styled('span')`
	font-variant: small-caps;
	letter-spacing: 4px;
	color: ${lightBeige};
	font-family: 'BWHaas', sans-serif;
	font-size: 110%;
	text-decoration: line-through;
`;

const Event = styled('div')`
	margin-bottom: 40px;
`;

const EventImportant = styled('div')`
	margin-bottom: 40px;
	border: 1px solid white;
	padding: 15px;
`;

const A = styled('a')`
	color: ${lightBeige};
`;

const ImgVisuel = styled('img')`
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	display: block;
	width: 80%;
`;

class HomeEventsContent extends Component {
	render() {
		return (
			<PerfectScrollbar>
				<HomeEventsContentMain>
					<Event>
						<Title>11 octobre 2019</Title>
						<br />
						<i>
							<A href="/play/empreinte">L’Empreinte</A>
						</i>
						<br />
						<A href="https://theatre-vitez.com/" targe="_blank">
							Théâtre Antoine-Vitez
						</A>
						<br />
						<ImgVisuel src={visuel01} width="80%" alt="" />
					</Event>
					<Event>
						<Title>09 novembre 2019</Title>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A
							href="https://lumieredespectacle.univ-lille.fr"
							targe="_blank"
						>
							Université de Lille - Colloque « Lumière Matière »
							(Villeneuve d’Ascq)
						</A>
						<br />
						<ImgVisuel src={visuel02} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>14 juin 2019</TitleStrike>
						<br />
						<i>
							<A href="/play/arma">Au revoir mon amour</A>
						</i>
						<br />
						<A
							href="http://www.nouveau-theatre-montreuil.com"
							targe="_blank"
						>
							Nouveau Théâtre de Montreuil - Sortie de Résidence
						</A>
						<br />
						<ImgVisuel src={visuel03} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>20 avril 2019</TitleStrike>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A
							href="http://www.theatredelacite.com/accueil/festival-ecarts_2"
							targe="_blank"
						>
							Théâtre de la Cité Internationale - Festival Écarts
							(Paris 14e)
						</A>
						<br />
						<ImgVisuel src={visuel04} width="80%" alt="" />
					</Event>
					<EventImportant>
						<TitleStrike>25-26 janvier 2019</TitleStrike>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A href="https://www.youtube.com/watch?v=ZZAhLJvSRDE" targe="_blank">
							Château de Beaulieu - Festival Nuits du Sycomore
							(Mareuil-Sur-Belle)
						</A>
						<br />
						<br />- Spectacles-performances: <i>
							Éclairage Public
						</i>{' '}
						(Le Sycomore) / <i>L’Empreinte</i> (Le Sycomore) /{' '}
						<i>Au revoir mon amour</i> (Le Sycomore) /{' '}
						<i>La Pyramide !</i> (Carrelage Collectif) /{' '}
						<i>Billie</i> (Cie Avant L’Aube)
						<br />
						- Concerts: On lâche les chiens / Querelle
						<br />- Installations-Expositions: <i>
							Wild&Light #01
						</i>{' '}
						- Marie Deschamps & Philippe Guerrieri / Pierre Fourmeau
						<br />- Atelier: <i>L’acteur, cette larve</i> (Le
						Sycomore)
						<br />
						<br />
						<center>
							<iframe title="epFrame" width="90%" height="315" src="https://www.youtube.com/embed/ZZAhLJvSRDE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</center>
					</EventImportant>
					<Event>
						<TitleStrike>05 janvier 2019</TitleStrike>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A
							href="https://www.facebook.com/carrelagecollectif/?ref=br_rs"
							targe="_blank"
						>
							Espace Oppidum - Festival Chantiers Publics (Paris 11e)
						</A>
						<br />
						<ImgVisuel src={visuel05} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>23-25 novembre 2018</TitleStrike>
						<br />
						<i>
							<A href="/play/arma">Au revoir mon amour</A>
						</i>
						<br />
						<A href="#" targe="_blank">
							Loft du performer Alberto Sorbelli (Paris 11e)
						</A>
						<br />
						<ImgVisuel src={visuel06} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>18 novembre 2018</TitleStrike>
						<br />
						<i>
							<A href="/play/arma">Au revoir mon amour</A>
						</i>
						<br />
						<A href="https://legenerateur.com/" targe="_blank">
							Le Générateur - Soirée « À l’écoute du bal rêvé »
							(Gentilly)
						</A>
						<br />
						<ImgVisuel src={visuel07} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>04 octobre 2018</TitleStrike>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A href="http://www.silencio-club.com" targe="_blank">
							Silencio Club - Soirée « Tout le monde a peur du
							noir » (Paris 2e)
						</A>
						<br />
						<ImgVisuel src={visuel08} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>21-28 septembre 2018</TitleStrike>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A
							href="https://www.ens.fr/actualites/expositions-et-conferences-treve"
							targe="_blank"
						>
							Festival TREVE - École Normale Supérieure (Paris 5e)
						</A>
						<br />
						<ImgVisuel src={visuel09} width="80%" alt="" />
					</Event>
					<Event>
						<TitleStrike>20 septembre 2018</TitleStrike>
						<br />
						<i>
							<A href="/play/ep">Éclairage Public</A>
						</i>
						<br />
						<A
							href="https://www.facebook.com/clossauvage/"
							targe="_blank"
						>
							Landy Sauvage (Saint-Denis)
						</A>
						<br />
						<ImgVisuel src={visuel10} width="80%" alt="" />
					</Event>
				</HomeEventsContentMain>
			</PerfectScrollbar>
		);
	}
}

export default HomeEventsContent;
